import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'

import SEO from '~/components/seo'
import { Container } from '~/utils/styles'

import ProductGrid from '~/components/ProductGrid'

import styled from '@emotion/styled'

import { breakpoints } from '~/utils/styles'

const SearchBar = styled.div`
  margin: 6rem auto;
  width: 34rem;
  input {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 1rem;
    width: 100%;
    display: block;
    font-size: 2.4rem;
    &:focus {
      outline: 0;
    }
  }
`

const Search = ({ data }) => {
  const { edges: products } = data.allShopifyProduct
  const mappedProducts = products.map(product => product.node)

  const [value, setValue] = useState('')
  const [filtered, setFiltered] = useState(null)

  const handleChange = value => {
    setValue(value)

    const filteredProducts = [...mappedProducts].filter(product =>
      product.title.toUpperCase().includes(value.toUpperCase())
    )

    setFiltered(filteredProducts)
  }

  return (
    <Container>
      <SEO title="Search" />
      <SearchBar>
        <input
          value={value}
          onChange={e => handleChange(e.target.value)}
          placeholder="Searched item (min. 3 letters)"
        />
      </SearchBar>
      {filtered && value.length > 2 && <ProductGrid products={filtered} />}
    </Container>
  )
}

export const query = graphql`
  query {
    allShopifyProduct {
      edges {
        node {
          id
          title
          handle
          createdAt
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          images {
            id
            originalSrc
          }
          variants {
            price
          }
        }
      }
    }
  }
`

// export const query = graphql`
//   query {
//     allShopifyProduct {
//       edges {
//         node {
//           id
//           title
//           handle
//           createdAt
//           availableForSale
//           priceRange {
//             maxVariantPrice {
//               amount
//             }
//             minVariantPrice {
//               amount
//             }
//           }
//           images {
//             id
//             originalSrc
//             localFile {
//               childImageSharp {
//                 fluid(maxWidth: 720) {
//                   ...GatsbyImageSharpFluid_withWebp_tracedSVG
//                 }
//               }
//             }
//           }
//           variants {
//             price
//           }
//         }
//       }
//     }
//     prismic {
//       allCollectionss {
//         edges {
//           node {
//             body {
//               ... on PRISMIC_CollectionsBody1st_level {
//                 type
//                 primary {
//                   collection_title
//                   collection_id
//                 }
//               }
//               ... on PRISMIC_CollectionsBody2nd_level {
//                 type
//                 primary {
//                   collection_title
//                   collection_id
//                 }
//                 fields {
//                   collection_id
//                   collection_title
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default Search
